body {
  padding: 0;
  margin: 0;
}
.outer-wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 560px;
  background-position: center;
  background-size: cover;
  &.outer-wrapper--bg-1 {
    background-image: image-url("login_bg_big_1.png");
  }
  &.outer-wrapper--bg-2 {
    background-image: image-url("login_bg_big_2.png");
  }
}
.inner-wrapper {
  display: table;
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 188px;
  background: #fff;
}
.introduction {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  width: 320px;
  margin: 0 auto;
  color: #fff;
  font-size: 21px;
  text-align: center;
}
.main-image-container {
  position: absolute;
  z-index: -1;
  top: -227px;
  left: 0;
  width: 100%;
  text-align: center;
}
.main-image {
  display: inline-block;
  width: 274px;
  height: 228px;
  background-image: image-url("mobile/i-phone-6-s-silver.png");
  background-size: 274px 228px;
}
.banner-container {
  display: table-cell;
  vertical-align: middle;
  height: 155px;
  background: #eee;
  text-align: center;
}
.banner {
  display: inline-block;
  box-sizing: border-box;
  max-width: 355px;
  min-width: 302px;
  padding: 0 10px;
  text-align: left;
}
.icon-image {
  display: inline-block;
  float: left;
  width: 55px;
  height: 55px;
  background-image: image-url("mobile/app-icon.png");
  background-size: 55px 55px;
}
.main-text {
  margin: 6px 0 0 69px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.button-container {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 18px;
  text-align: center;
  a {
    display: inline-block;
    width: 136px;
    height: 45px;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
    line-height: 45px;
    text-decoration: none;
    &.install-app-button {
      float: left;
      background: #4778d9;
    }
    &.launch-app-button {
      float: right;
      background: #27ae60;
    }
  }
}
.sub-text {
  clear: both;
  color: #808080;
  font-size: 14px;
  text-align: center;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .main-image {
    background-image: image-url("mobile/i-phone-6-s-silver@2x.png");
  }
  .icon-image {
    background-image: image-url("mobile/app-icon@2x.png");
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .main-image {
    background-image: image-url("mobile/i-phone-6-s-silver@3x.png");
  }
  .icon-image {
    background-image: image-url("mobile/app-icon@3x.png");
  }
}
